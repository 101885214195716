type MetaDescriptor =
  | {
      charSet: "utf-8";
    }
  | {
      title: string;
    }
  | {
      name: string;
      content: string;
    }
  | {
      property: string;
      content: string;
    }
  | {
      httpEquiv: string;
      content: string;
    }
  | {
      "script:ld+json": LdJsonObject;
    }
  | {
      [name: string]: string;
      tagName: "meta" | "link";
    }
  | {
      [name: string]: unknown;
    };
type LdJsonObject = {
  [Key in string]: LdJsonValue;
} & {
  [Key in string]?: LdJsonValue | undefined;
};
type LdJsonArray = LdJsonValue[] | readonly LdJsonValue[];
type LdJsonPrimitive = string | number | boolean | null;
type LdJsonValue = LdJsonPrimitive | LdJsonObject | LdJsonArray;

export type SEOMetadata = {
  title: string;
  description: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  robotsFollowIndex?: boolean;
  canonical?: string;
};

export const SEOMetaDescriptorsBuilder =
  (baseURL: string) =>
  ({
    title,
    description,
    ogTitle,
    ogDescription,
    ogImage,
    ogType,
    ogUrl,
    robotsFollowIndex = true,
    canonical,
  }: SEOMetadata): MetaDescriptor[] => {
    return [
      { title },
      canonical && {
        tagName: "link",
        rel: "canonical",
        href: canonical,
      },

      {
        property: "og:title",
        content: ogTitle ?? title,
      },
      {
        name: "description",
        content: description,
      },
      {
        property: "og:description",
        content: ogDescription ?? description,
      },
      {
        property: "og:type",
        content: ogType ?? "website",
      },
      {
        property: "og:image",
        content: ogImage ?? `${baseURL}/og-img.png`,
      },
      {
        property: "og:image:url",
        content: ogImage ?? `${baseURL}/og-img.png`,
      },
      {
        property: "og:image:alt",
        content: title,
      },
      ogUrl && {
        property: "og:url",
        content: ogUrl,
      },
      robotsFollowIndex && {
        property: "robots",
        content: "index, follow",
      },
    ].filter(Boolean);
  };
